<template>
  <section class="follows">
    <img :src="bluetop" class="top-img" alt="bluetop" />
    <div class="center">
      <div class="flex">
        <h3>Kövess minket</h3>
        <div class="social">
          <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
            <font-awesome-icon :icon="['fab', 'facebook-f']" />
          </a>
          <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
        </div>
      </div>
    </div>
    <img :src="bluebottom" class="bottom-img" alt="bluebottom" />
  </section>
</template>

<script>
import bluetop from '../../images/top.png'
import bluebottom from '../../images/bottom.png'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

// Add the icons to the library
library.add(faFacebookF, faInstagram)

export default {
  name: 'AppFollows',
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      bluetop,
      bluebottom
    }
  }
}
</script>

<style lang="scss" scoped>
@import './AppFollows.scss';
</style>
