<template>
  <section id="portfolio" className="portfolio">
    <div className="container">
      <h3>Portfólio</h3>
      <div class="portfolio-cards">
        <a v-for="(portfolioItem, index) in portfolio" :key="index" :href="portfolioItem.path" target="_blank" rel="noreferrer" class="portfolio-card">
          <img :src="portfolioItem.img" alt="portfolio-image" />
          <h4>{{ portfolioItem.text }}</h4>
        </a>
      </div>
    </div>
  </section>
</template>
<script>
import { portfolio } from '../../data';

export default {
  name: "AppPortfolio",

  data() {
    return {
      portfolio
    };
  }
};
</script>
<style lang="scss">
@import "./AppPortfolio.scss";
</style>