<template>
  <header ref="headerRef">
    <div class="container">
      <div class="center">
        <div
          :class="['header-container', { scrolled }]"
          @click="handleHeaderClick"
        >
          <div class="flex">
            <font-awesome-icon :icon="['fas', 'bars']" />
            Net4Yard
          </div>
        </div>
        <div class="absolute">
          <div
            :class="[
              'header-dropdown',
              { show: dropdownVisible, small: scrolled },
            ]"
          >
            <div class="dropdown-top">
              <img :src="logo" class="logo" alt="logo" />
            </div>
            <div class="dropdown-bottom">
              <ul>
                <li v-for="section in sections" :key="section">
                  <a
                    :href="section"
                    @click="handleLinkClick(section)"
                    :class="{ active: activeNav === section }"
                  >
                    <font-awesome-icon :icon="getIcon(section)" />
                    {{ getSectionLabel(section) }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import logo from '../../images/logo.png';

export default {
  name: 'AppHeader',
  setup() {
    const scrolled = ref(false);
    const dropdownVisible = ref(false);
    const activeNav = ref('#');
    const headerRef = ref(null);

    const sections = ['#hero', '#profession', '#services', '#choose', '#portfolio', '#contact'];

    const handleScroll = () => {
      scrolled.value = window.scrollY > 25;

      let currentSection = '#';
      sections.forEach(section => {
        const element = document.querySelector(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
            currentSection = section;
          }
        }
      });

      activeNav.value = currentSection;
    };

    const handleClickOutside = (event) => {
      if (headerRef.value && !headerRef.value.contains(event.target)) {
        dropdownVisible.value = false;
      }
    };

    const handleHeaderClick = () => {
      dropdownVisible.value = !dropdownVisible.value;
    };

    const handleLinkClick = (section) => {
      activeNav.value = section;
      dropdownVisible.value = false;
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      document.addEventListener('mousedown', handleClickOutside);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    });

    const getIcon = (section) => {
      switch (section) {
        case '#hero':
          return ['fas', 'home'];
        case '#profession':
          return ['fas', 'gear'];
        case '#services':
          return ['fas', 'tasks'];
        case '#choose':
          return ['fas', 'share-square'];
        case '#portfolio':
          return ['fas', 'pencil'];
        case '#contact':
          return ['fas', 'envelope'];
        default:
          return null;
      }
    };

    const getSectionLabel = (section) => {
      switch (section) {
        case '#hero':
          return 'Főképernyő';
        case '#profession':
          return 'Mivel foglalkozunk?';
        case '#services':
          return 'Szolgáltatásaink';
        case '#choose':
          return 'Miért válasszon minket?';
        case '#portfolio':
          return 'Portfólio';
        case '#contact':
          return 'Kapcsolat';
        default:
          return '';
      }
    };

    return {
      scrolled,
      dropdownVisible,
      activeNav,
      headerRef,
      sections,
      logo,
      handleHeaderClick,
      handleLinkClick,
      getIcon,
      getSectionLabel
    };
  }
};
</script>

<style lang="scss" scoped>
@import "./Appheader.scss";
</style>
