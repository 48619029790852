<template>
    <footer>
      <p>@2024 Net4Yard minden jog fenntartva</p>
    </footer>
</template>
<script>
export default {
    name: 'AppFooter'
}
</script>
<style lang="scss">
    @import './AppFooter.scss';
</style>