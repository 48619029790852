<template>
    <section id='choose' className="choose">
      <div className="container">
        <div className="row">
          <div className="col-66">
            <div className="panel">
              <h3>Miért válasszon minket?</h3>
              <p>Velünk való együttműködés azt jelenti, hogy egy olyan csapattal dolgozik, amely elkötelezett a kiválóság iránt minden projektben. Tapasztalatunk, technikai hozzáértésünk és az innováció iránti szenvedélyünk garantálja az Ön sikerét a digitális térben. Lépjen kapcsolatba velünk még ma, és fedezze fel, hogyan segíthetünk Önnek megvalósítani céljait!</p>
            </div>
          </div>
          <div className="col-33">
            <div className="panel">
              <h3>Szakértelem</h3>
              <p>Közel egy évtizedes tapasztalatunk és elkötelezettségünk biztosítja, hogy minden projektünk a legmagasabb minőséget képviselje.</p>
            </div>
          </div>
        </div>
        <div className="row mt-25">
          <div className="col-33">
            <div className="panel">
              <h3>Innováció</h3>
              <p>Folyamatosan lépést tartunk a legújabb technológiai trendekkel, hogy Ön mindig a legmodernebb megoldásokat kapja.</p>
            </div>
          </div>
          <div className="col-66">
            <div className="panel">
              <h3>Minőség</h3>
              <p className='mb-50'>Számunkra a minőség nem csupán elvárás, hanem alapelv. Minden projektünknél a legmagasabb színvonalat célozzuk meg.</p>
              <div className='justify-center'>
                <a href='#contact'><FaEnvelope />Kapcsolat</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'AppChoose'
}
</script>

<style lang="scss" scoped>
    @import './AppChoose.scss';
</style>