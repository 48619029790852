export const questions = [
    {
        id: 1,
        title: 'Milyen típusú projekteket vállalnak?',
        text: 'Vállalunk webes alkalmazások, mobilalkalmazások, e-kereskedelmi platformok, belső vállalati rendszerek és egyedi szoftvermegoldások fejlesztését.'
    },
    {
        id: 2,
        title: 'Mennyi ideig tart egy projekt megvalósítása?',
        text: 'Ez a projekt bonyolultságától és méretétől függ, de egy egyszerű webalkalmazás általában 2-3 hónapot vesz igénybe, míg egy komplex rendszer akár 6-12 hónapig is eltarthat.'
    },
    {
        id: 3,
        title: 'Készítenek-e reszponzív dizájnt, amely minden eszközön jól mutat?',
        text: 'Igen, minden projektünk reszponzív dizájnnal készül, hogy minden eszközön jól mutasson és használható legyen.'
    },
    {
        id: 4,
        title: 'Tudnak-e segíteni a UX/UI tervezésben?',
        text: 'Igen, van saját UX/UI tervező csapatunk, akik segítenek a felhasználóbarát és esztétikus dizájn kialakításában.'
    },
    {
        id: 5,
        title: 'Hogyan kommunikálnak a fejlesztés során?',
        text: 'Rendszeres heti státusz jelentéseket készítünk és meetingeket tartunk az ügyfelekkel, hogy nyomon kövessük a projekt előrehaladását és megvitassuk az esetleges kérdéseket vagy változtatásokat.'
    },
    {
        id: 6,
        title: 'Mennyibe kerül egy projekt?',
        text: 'Az ár a projekt bonyolultságától és időtartamától függ, de mindent részletesen egyeztetünk az ügyféllel a projekt megkezdése előtt.'
    },
];

export const portfolio = [
    {
        id: 1,
        path: 'https://bordasmariaugyved.hu/',
        img: require('./images/ugyved.png'),
        text: 'Prof. Dr. Bordás Mária Bemutatkozó oldala'
    },
    {
        id: 2,
        path: 'https://bordasmariaugyved.hu/',
        img: require('./images/ugyved.png'),
        text: 'Prof. Dr. Bordás Mária Bemutatkozó oldala'
    },
    {
        id: 3,
        path: 'https://bordasmariaugyved.hu/',
        img: require('./images/ugyved.png'),
        text: 'Prof. Dr. Bordás Mária Bemutatkozó oldala'
    },
    {
        id: 4,
        path: 'https://bordasmariaugyved.hu/',
        img: require('./images/ugyved.png'),
        text: 'Prof. Dr. Bordás Mária Bemutatkozó oldala'
    },
];
