<template>
  <div>
    <AppHeader />
    <AppHero />
    <AppProfession />
    <AppServices />
    <AppChoose />
    <AppPortfolio />
    <AppContact />
    <AppFollows />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader/AppHeader.vue';
import AppHero from './components/AppHero/AppHero.vue';
import AppProfession from './components/AppProfession/AppProfession.vue';
import AppServices from './components/AppServices/AppServices.vue';
import AppChoose from './components/AppChoose/AppChoose.vue';
import AppPortfolio from './components/AppPortfolio/AppPortfolio.vue';
import AppContact from './components/AppContact/AppContact.vue';
import AppFollows from './components/AppFollows/AppFollows.vue';
import AppFooter from './components/AppFooter/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppHero,
    AppProfession,
    AppServices,
    AppChoose,
    AppPortfolio,
    AppContact,
    AppFollows,
    AppFooter
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: #7bbdbe;
}

::-webkit-scrollbar-thumb {
  background: #068291;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0d506d;
}

.container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 1170px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  max-width: none;
  width: calc(100% + 20px);

  .col-25 {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(25% - 30px);
  }

  .col-33 {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(33.33% - 30px);

    @media only screen and (max-width: 955px) {
      width: calc(100% - 30px);
      display: block;
    }
  }

  .col-50 {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(50% - 30px);

    @media only screen and (max-width: 955px) {
      width: calc(100% - 30px);
      display: block;
    }
  }

  .col-66 {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(66.66% - 30px);

    @media only screen and (max-width: 955px) {
      width: calc(100% - 30px);
      display: block;
    }
  }

  .col-75 {
    flex: 0 0 auto;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(75% - 30px);
  }
}

</style>