<template>
  <section id="profession" class="profession">
    <div class="container width">
      <div class="row">
        <div class="col-50">
          <div class="middle">
            <div class="profession-text-box">
              <h2>{{ cards[activeIndex].textBoxTitle }}</h2>
              <p>{{ cards[activeIndex].content }}</p>
            </div>
          </div>
        </div>
        <div class="col-50 cards-container">
          <div
            v-for="(card, index) in cards"
            :key="index"
            :class="['card', { active: index === activeIndex }]"
            :style="getCardStyle(index)"
            @click="handleCardClick(index)"
          >
            <h3>{{ card.title }}</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  name: 'AppProfession',
  setup() {
    const cards = ref([
      { title: "Állandó kapcsolattartás", textBoxTitle: "Megbízhatóság", content: "Csapatunk a minőség mellett törekszik a gyorsaságra, megbízhatóságra. Önnek nem kell aggódnia afelől, hogy weboldala profi kezekben van." },
      { title: "Arculatterv készítés", textBoxTitle: "Arculattervek", content: "Kezdő vállalkozó? Vállalkozásának arculatát is megtervezzük. Logó, névjegykártya és egyéb grafikai elemeket készítünk, hogy igazán kitűnjön a tömegből." },
      { title: "Gyors, megbízható munka", textBoxTitle: "Kapcsolattartás", content: "Vállalkozásunkkal folyamatosan kapcsolatban maradhat a projekt során, így mindig tudja, hogy hol tartunk." },
      { title: "Állandó kapcsolattartás", textBoxTitle: "Rólunk", content: "Csapatunk fiatalos és rugalmas. Minden egyes projektet, ügyfelet egyedi módon kezelünk." },
      { title: "Fiatalos, rugalmas csapat", textBoxTitle: "Marketing", content: "Ha készen van weboldala, attól még nem biztos, hogy sikeres lesz a vállalkozása. Fontos, hogy a weboldalát megfelelően reklámozza. Ebben is segítünk." }
    ]);

    const activeIndex = ref(2);

    const intervalId = ref(null);

    onMounted(() => {
      intervalId.value = setInterval(() => {
        activeIndex.value = (activeIndex.value + 1) % cards.value.length;
      }, 10000);
    });

    onUnmounted(() => {
      clearInterval(intervalId.value);
    });

    const handleCardClick = (index) => {
      activeIndex.value = index;
    };

    const getCardStyle = (index) => {
      const relativeIndex = (index - activeIndex.value + cards.value.length) % cards.value.length;
      const position = Math.abs(relativeIndex - 2);
      return {
        zIndex: position === 0 ? cards.value.length : cards.value.length - position,
        opacity: position === 0 ? 1 : 0.5,
        transform: `translateY(${(relativeIndex - 2) * 20}px) scale(${position === 0 ? 1.1 : 1})`,
        backgroundColor: `hsl(${index * 72}, 70%, 70%)`
      };
    };

    return {
      cards,
      activeIndex,
      handleCardClick,
      getCardStyle
    };
  }
};
</script>
<style lang="scss">
@import "./AppProfession.scss";
</style>