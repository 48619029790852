<template>
    <section id='services' className="services">
      <div className="container">
        <h3>Szolgáltatásaink</h3>
        <div className="row mt-32 gap-15">
          <div className="col-33">
            <div className="services-card">
              <h4>Egyedi weboldal</h4>
              <p>Minden ügyfelünknek egyedileg tervezett és fejlesztett weboldalakat készítünk, figyelve az aktuális trendekre és igényekre.</p>
            </div>
          </div>
          <div className="col-33">
            <div className="services-card">
              <h4>Szoftverfejlesztés</h4>
              <p>Robusztus és megbízható szoftvereket, applikációkat készítünk, figyelembe véve az ügyfél igényeit és a legújabb technológiákat.</p>
            </div>
          </div>
          <div className="col-33">
            <div className="services-card">
              <h4>Marketing</h4>
              <p>Online marketing szolgáltatásokat is nyújtunk, hogy weboldala minél több látogatót vonzzon és minél több ügyfelet szerezzen.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
export default {
    name: 'AppServices'
}
</script>
<style lang="scss">
    @import './AppServices.scss'
</style>