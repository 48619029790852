<template>
  <section id="contact" class="contact">
    <div class="container">
      <h3 class="contact-title">Kapcsolat</h3>
      <div class="max-width">
        <p class="paragraph-title">
          Készen áll arra, hogy új szintre emelje digitális jelenlétét? Lépjen
          kapcsolatba velünk még ma, és kezdjük el közösen a jövő építését!
        </p>
      </div>
      <div class="row">
        <div class="col-66">
          <div class="questions">
            <div v-for="(question, index) in questions" :key="index">
              <div
                class="flex question__title"
                :class="{ active__arrow: activeIndex === index }"
                @click="handleClick(index)"
              >
                <font-awesome-icon :icon="['fas', 'angle-right']" />
                <h4>{{ question.title }}</h4>
              </div>
              <p
                :class="{
                  active__text: activeIndex === index,
                  text: activeIndex !== index,
                }"
              >
                {{ question.text }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-33">
          <div class="personal__card">
            <input type="name" placeholder="Név" />
            <input type="email" placeholder="E-mail" />
            <input type="subject" placeholder="Tárgy" />
            <textarea
              name="textarea"
              cols="30"
              rows="10"
              placeholder="Üzenet"
            ></textarea>
            <button>Küldés</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { questions } from "../../data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faAngleRight);

export default {
  name: "AppContact",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      questions,
      activeIndex: null,
    };
  },
  methods: {
    handleClick(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./AppContact.scss";
</style>
